<template>
    <div>
      <h1 class="app-title pl-1">
        <router-link to="dashboard">
          <b-icon icon="arrow-left-circle"  v-b-popover.hover="'back to dashboard'"></b-icon>
        </router-link>
        Carbon reporting
      </h1>
      <form class="form-inline">
        <div class="form-group mb-2 action-bar">
          <input type="month" class="ml-1 form-control form-control-sm" v-model="selectedMonth">
          <a class="btn btn-secondary btn-sm"
            target="_blank"
            :href="'/carbonReporting?month='+selectedMonth">
            <b-icon icon="download"/> Download report for {{ monthList }}
          </a>
        </div>
      </form>
    </div>
</template>

<script>
export default {
   components: {
  },
  data: function() { 
    return {
      selectedMonth: "",
    }
  },
  computed: {
      monthList() {
        let retour = ""
        const d = new Date(this.selectedMonth+"-01")
        d.setMonth(d.getMonth() - 2)
        retour += d.toLocaleDateString('default', { month: 'long' })
        retour += " , "
        d.setMonth(d.getMonth() + 1)
        retour += d.toLocaleDateString('default', { month: 'long' })
        retour += " , "
        d.setMonth(d.getMonth() + 1)
        retour += d.toLocaleDateString('default', { month: 'long' })
        retour += " "+d.getFullYear()
        return retour
      },
  },
  mounted() {
    let today = new Date();
    today.setMonth(today.getMonth() - 1);
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    this.selectedMonth = yyyy+"-"+mm;
  },
  methods: {
  },
}
</script>

<style>

</style>