<template>
  <div>
    <h1 class="app-title pl-1">
      <router-link to="dashboard">
        <b-icon
          icon="arrow-left-circle"
          v-b-popover.hover="'back to dashboard'"
        ></b-icon>
      </router-link>
      Tabular dashboard
    </h1>
    <div class="row">
      <div class="col-12 form-inline">
        <input
          type="month"
          min="2017-01"
          class="ml-1 form-control form-control-sm"
          v-model="selectedMonth"
        />
        <a
          class="btn btn-success btn-sm ml-5"
          target="_blank"
          :href="
            'https://sims-2-140009.appspot.com/commcare/dashboard.php?month=' +
              monthIndex +
              '&excel'
          "
        >
          <b-icon icon="download" /> Download excel
        </a>
      </div>
    </div>
    <h3 v-if="data.length === 0" class="pt-5">
      Please wait, we are loading your tabular dashboard...<BR />
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </h3>
    <b-table id="dashboard-table" 
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :fields="fields"
        striped hover small 
        :items="data">
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  data: function() {
    return {
      selectedMonth: "",
      data: [],
      fields: [],
      sortBy: "site",
      sortDesc: false,
    };
  },
  watch: {
    selectedMonth() {
      this.data = [];
      this.axios
        .get("/api/dashboard?month=" + this.monthIndex)
        .then((response) => {
           response.data.properties.forEach(element => {
               this.fields.push({
                   key: element,
                   sortable: true
               })
           }); 
          this.data = Object.values(response.data.data);
        });
    },
  },
  mounted() {
    let today = new Date();
    today.setMonth(today.getMonth() - 1);
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    this.selectedMonth = yyyy + "-" + mm;
  },
  computed: {
    monthIndex() {
      let today = new Date();
      let selected = new Date(this.selectedMonth + "-01");
      let days = Math.round((today - selected) / (1000 * 60 * 60 * 24));
      return Math.round(days / 30);
    },
  },
  methods: {},
};
</script>

<style>
#dashboard-table {
    border: none;
}
#dashboard-table > tbody > tr > td {
  font-family: Arial, Helvetica, sans-serif;
  vertical-align: middle;
}
#dashboard-table > thead > tr > th {
  border: none
}
</style>
