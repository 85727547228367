import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(Vuex)

import CommcareDashboard from './components/CommcareDashboard.vue'
import CarbonReport from './components/CarbonReport.vue'
import Login from './components/Login.vue'
import TabularDashboard from './components/TabularDashboard.vue'

const store = new Vuex.Store({
  state: {
    user: {}
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    }
  }
})

const routes = [
  { path: '/dashboard', component: CommcareDashboard },
  { path: '/', component: CommcareDashboard },
  { path: '/carbon', component: CarbonReport },
  { path: '/login', component: Login },
  { path: '/tab-dashboard', component: TabularDashboard }
]
const router = new VueRouter({
  routes // short for `routes: routes`
})
router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && (!store.state.user || !store.state.user.email)) next({ path: '/login' })
  else next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')