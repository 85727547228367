<template>
<div>
    <img src='/commcare/img/logo.png'>
    <div v-if='loading' class='login-box'>
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-if='!loading' class='login-box'>      
      <p class='pt-4'>Access to this application is restricted to TeukSaat employees.</p>
      <p>Please login using your Google @teuksaat1001.com account.</p>
      <div id="google-login-btn" v-google-identity-login-btn="{ clientId, locale:'en', width:'300px' }"> Login with Google</div>
    </div>
</div>
</template>
<script>

import GoogleSignInButton from 'vue-google-identity-login-btn';

export default {
  name: 'Login',
  directives: {
    GoogleSignInButton
  },
  data: function () {
    return {
      clientId: '736073153554-ka51oha7hvt3n5q4b1vqfhcsckpc3s96.apps.googleusercontent.com',
      loading: true,
      showPermission: false,
      excelFileSales: null,
      excelFileWins: null,
      errorMessage: "",
      uploadingFile: false,
    }
  },
  methods: {
    onGoogleAuthSuccess(token) {     
      this.axios.post("/api/login", {token:token}).then(response => {
        if(response.data.email.includes('@teuksaat1001') || response.data.email.includes('@1001fontaines')) {
          this.$store.commit('setUser', {
            email: response.data.email,
            permissions: JSON.parse(response.data.permissions)
          })
          this.$router.push({ path: '/dashboard' })
        }
      });
    },
    OnGoogleAuthFail (error) {
      console.log(error)
    },
  },
  computed: {
      user: function() {
        return this.$store.state.user
      },
  },
  mounted() {
    this.axios.get("/api/getAuth").then(response => {
      let email = response.data.email;
      if(email && (email.includes('@teuksaat1001') || email.includes('@1001fontaines'))) {
        this.$store.commit('setUser', {
            email: response.data.email,
            permissions: JSON.parse(response.data.permissions)
        })
        this.$router.push({ path: '/dashboard' })
      }
      this.loading = false;
    });
  }
}
</script>

<style scoped>
.login-box{
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>