<template>
<div v-if="user && user.permissions && user.permissions.includes('close_site') && selectedSite.closed === 0" class="text-center mt-2">
    <h4>Please report site closure in Commcare App</h4>
    <!-- <p>An email will be sent to all management to inform them.</p>
    <b-form-group label-cols="4" label-cols-lg="2" label="Closure date" label-for="closure-date">
        <b-form-input id="closure-date" v-model="siteClosureDate" type="date"></b-form-input>
    </b-form-group>
    <b-form-group label-cols="4" label-cols-lg="2" label="Closure reason" label-for="closure-reason">
        <b-form-select id="closure-reason" v-model="siteClosureSelect" :options="closureReasons"></b-form-select>
    </b-form-group>
    <b-form-group label-cols="4" label-cols-lg="2" label="Closure description" label-for="closure-description">
        <b-form-textarea
        id="closure-description"
        v-model="siteClosureReason"
        placeholder="សូមបញ្ជាក់ពីមូលហេតុអោយបានជាក់លាក់  |  Please specify the reasons in detail"
        ></b-form-textarea>
    </b-form-group>        
    <b-button v-if="validating === false" :disabled="siteClosureReason.length < 2 || siteClosureSelect === closureReasons[0]" variant="danger" 
        @click="closeSite(selectedSite)" class="mt-2 mb-4">Close site</b-button>
    <b-spinner v-else variant="success" label="Spinning"></b-spinner> -->
</div>
</template>

<script>
export default {
  props: {
    selectedSite: {
      type: Object,
    },
  },
  watch : {
  },
  data: function() {
    return {
      validating: false,
      siteClosureReason: "",
      siteClosureSelect: "",
      siteClosureDate: "",
      closureReasons: [
        "ជ្រើសរើសជម្រើសមួយ | Choose one option",
        "បញ្ហាគុណភាពទឹក - Water quality problem",
        "បញ្ហាគុណភាព - Technical problem",
        "បញ្ហាបំ​រ៉ែ​បំ​រួល​អាកាសធាតុ - Climate change",
        "បញ្ហាប្រភពទឹករាំងស្ងួត - Drought",
        "បញ្ហាទឹកជំនន់ - Flooding",
        "ទំនាក់ទំនងជាមួយអញ្ញាធរ - Relations with public autorities",
        "កូវិត ១៩ - Covid 19",
        "បញ្ហាធនធានមនុស្ស - HR problem",
        "សហគ្រិនលាលែង - Entrepreneur resignation",
        "ខ្វះប្រតិបត្តិករ - Lack operators",
        "ទីប្រឹក្សាសហគ្រិនបញ្ឈប់សហគ្រិន - Turnover of entrepreneur by advisors",
        "បញ្ហាផ្សេងៗ - Others",
      ],
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  filters: {
  },
  methods: {
    closeSite(site) {
      this.validating = true
      this.axios.post("/api/close", 
        {
          site: site.site,
          reason: this.siteClosureSelect,
          closureDate: this.siteClosureDate,
          description: this.siteClosureReason,
        }).then(() => {
          this.validating = false
          this.$emit('close');
      });
    },
  },
  mounted() {
    this.siteClosureSelect = this.closureReasons[0]
    this.siteClosureDate = new Date().toISOString().slice(0, 10)
  }
}
</script>