<template>
  <div id="app">
    <div class='logout-box' v-if="user && user.email">
      Logged in as {{ user.email }}
      <router-link 
        to="/carbon"
        class='ml-2'>Carbon report</router-link>

      <a v-if="user && user.permissions && user.permissions.includes('import_forecast')" 
        v-b-modal.modal-import-forecast
        href='#'
        class='ml-2'>Import forecast</a>


      <a v-if="user && user.permissions && user.permissions.includes('import_site_profile')" 
        v-b-modal.modal-import-site-profile
        href='#'
        class='ml-2'>Site</a>


      <a href='#' @click.prevent='logout' class='ml-2'>Logout</a>
    </div>
    <router-view></router-view>
    <b-modal ref="modal-import-forecast" id="modal-import-forecast" title="Import sales forecast" ok-title="Import" @ok="importFile">
      <p v-if="!uploadingFile">
        The file should be an excel file with only 2 columns : one with the site code, one with the <b>previsions in jugs/day</b>.
      </p>
      <a href="/excelTemplates/SalesForecast.xlsx" download>Download an excel file example for sales forecast</a>
      <b-form-file
        v-if="!uploadingFile"
        v-model="excelFileSales"
        :state="Boolean(excelFileSales)"
        placeholder="Choose a SALES forecast excel file..."
        drop-placeholder="Drop file here..."
      ></b-form-file>
      <BR/><BR/>
      <a href="/excelTemplates/WinSForecast.xlsx" download>Download an excel file example for WinS forecast</a>
      <b-form-file
        v-if="!uploadingFile"
        v-model="excelFileWins"
        :state="Boolean(excelFileWins)"
        placeholder="Choose a WINS forecast excel file..."
        drop-placeholder="Drop file here..."
      ></b-form-file>
      <b-spinner v-if="uploadingFile" variant="primary" label="Spinning"></b-spinner>
      <b-alert v-if="errorMessage != ''" variant="danger" class="mt-2" show>{{ errorMessage }}</b-alert>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'App',
  data: function () {
    return {
      clientId: '736073153554-ka51oha7hvt3n5q4b1vqfhcsckpc3s96.apps.googleusercontent.com',
      loading: true,
      showPermission: false,
      excelFileSales: null,
      excelFileWins: null,
      excelSiteProfile: null,
      errorMessage: "",
      uploadingFile: false,
      uploadingSiteFile: false,
    }
  },
  methods: {
    importFile(bvModalEvt) {
      bvModalEvt.preventDefault()
      if((this.excelFileWins && this.excelFileWins.name && this.excelFileWins.name.endsWith("xlsx"))
      || (this.excelFileSales && this.excelFileSales.name && this.excelFileSales.name.endsWith("xlsx"))) {
        this.errorMessage = ""
        this.uploadingFile = true
        let formData = new FormData()
        if(this.excelFileWins){
          formData.append('excelFileWins', this.excelFileWins, this.excelFileWins.name)
        }
        if(this.excelFileSales){
          formData.append('excelFileSales', this.excelFileSales, this.excelFileSales.name)
        }
        this.axios({
          method: "post",
          url: "/api/importForecast",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          this.uploadingFile = false
          this.$refs['modal-import-forecast'].hide()
        })
        .catch((error) => {
          this.uploadingFile = false
          console.log(error)
          this.errorMessage = error;
        });
      } else {
        this.errorMessage = "Select at least one file with .xlsx extension."
      }
    },
    importSiteProfile(bvModalEvt) {
      bvModalEvt.preventDefault()
      if((this.excelSiteProfile && this.excelSiteProfile.name && this.excelSiteProfile.name.endsWith("xlsx")))
       {
        this.errorMessage = ""
        this. uploadingSiteFile = true
        let formData = new FormData()
        if(this.excelSiteProfile){
          formData.append('excelSiteProfile', this.excelSiteProfile, this.excelSiteProfile.name)
        }
        this.axios({
          method: "post",
          url: "/importSiteProfile",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          this. uploadingSiteFile = false
          this.$refs['modal-import-site-profile'].hide()
        })
        .catch((error) => {
          this. uploadingSiteFile = false
          console.log(error)
          this.errorMessage = error;
        });
      } else {
        this.errorMessage = "Select at least one file with .xlsx extension."
      }
    },
    logout() {
      this.axios.get("/api/logout")
      this.$store.commit('setUser', {})
      this.$router.push({ path: '/login' })
    },
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}
</script>

<style>
#app {
  font-family: Gotham, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
h1,h2,legend {
  text-align: center;
}
@font-face {
  font-family: Gotham;
  src: url("~@/assets/GothamRnd-Medium.otf") format("opentype");
}
.google-signin-button {
  color: white;
  background-color: #36a674;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.login-box {
  padding-top: 50px;
}
.logout-box {
  position:absolute;
  right: 0px;
  font-size: 0.6rem;
  padding-right: 10px;
}
</style>
