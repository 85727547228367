<template>
  <span>
    <a
      :id="'s'+site.site"
      href="#"
      @click.prevent="$emit('siteSelected', site)"
      :class="{
        'site' : true,
        'validated' : site.isValidated,
        'fValidated' : site.isFinanceValidated,
        'warning' : site.forms.length < 6
      }">
      {{ site.site }}
      <span v-if="missingForms.length > 0 && missingForms.length != 5" class="site-badge">
        <b-icon icon="exclamation-triangle-fill"></b-icon>
      </span>
    </a>
    <b-tooltip v-if="missingForms.length > 0 && site.closed === 0 && site.nop === 0" :target="'s'+site.site" triggers="hover" class="site-tooltip" no-fade>
      Missing forms : 
      <ul class="text-left">
        <li v-for="(form,index) in missingForms" :key="site.site+'f'+index">{{form}}</li>
      </ul>
    </b-tooltip>
    <b-tooltip v-else-if="site.closed === 1 && site.nop === 0" :target="'s'+site.site" triggers="hover" class="site-tooltip" no-fade>
      This site is closed
      <BR/>Reason : {{ site.closedReason.Closing_reason }}
      <BR/>Closure date : {{ site.closedReason.Closing_date | toDateString }}
    </b-tooltip>
    <b-tooltip v-else-if="site.nop === 1" :target="'s'+site.site" triggers="hover" class="site-tooltip" no-fade>
      This site has never been opened
    </b-tooltip>
  </span>
</template>

<script>
export default {
  props: {
    site: {
      type: Object,
    }
  },
  data: function() {
    return {
      missingForms : [
        "1. Register Sales",
        "2. Settle Invoices",
        "3. Register Expenses",
        "5. Production Report",
        "6. Site Checklist",
      ]
    }
  },
  mounted() {
    const filtered = this.missingForms.filter(item => this.site.forms.indexOf(item) === -1);
    this.missingForms = filtered;
  },
  filters: {
    toDateString(val) {
      const d = new Date(Date.parse(val))
      return d.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>

<style>
  .site {
    border: solid #1EAEDB 1px;
    border-radius: 50%;
    display: inline-block;
    width: 34px;
    text-align: center;
    height: 34px;
    text-decoration: none;
    color: black;
    font-size: 0.8rem;
    font-weight: normal;
    margin: 2px;
    padding-top: 6px;
    position: relative;
  }
  .site:hover {
    background-color: green !important;
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
  .validated {
    background-color: #aaffaa;
  }
  .site-badge {
    box-sizing: border-box;
    background: #4c4c4c;
    font-size: 0.7em;
    color: yellow;
    width: 18px;
    height: 18px;
    margin: 0;
    border-radius: 50%;
    position: absolute;
    top: 22px;
    right: -6px;
    padding-top: 0px;
  }
  .tooltip>.tooltip-inner {
    text-align: left;
    padding:20px;
    background-color: #000000AA;
    font-weight: bold;
  }
  .tooltip>.tooltip-inner>ul {
    padding-left: 20px;
  }
</style>