<template>
    <div>
      <h1 class="app-title pl-1">Site reports from Commcare</h1>
      <form class="form-inline">
        <div class="form-group mb-2 action-bar d-flex flex-wrap">
          <input type="month" min="2017-01" class="ml-1 form-control form-control-sm" v-model="selectedMonth">
          <span v-if="selectedMonth >= '2019-01'">
            <a v-if="user && user.permissions && user.permissions.includes('view_activity_indicator')" class="btn btn-primary btn-sm"
              target="_blank"
              :href="'/monthlyReport?month='+selectedMonth+'&report=activityindicator&timestamp='+ts">
              <b-icon icon="download"/> Activity Indicator
            </a>
            <a v-if="user && user.permissions && user.permissions.includes('view_management_report')" class="btn btn-secondary btn-sm"
              target="_blank"
              :href="'/monthlyReport?month='+selectedMonth+'&report=management&timestamp='+ts">
              <b-icon icon="download"/> Mngt Dashboard
            </a>
            <a v-if="user && user.permissions && user.permissions.includes('view_f2f_report')" class="btn btn-success btn-sm"
              target="_blank"
              :href="'/monthlyReport?month='+selectedMonth+'&report=f2f&timestamp='+ts">
              <b-icon icon="download"/> F2F
            </a>
            <a v-if="user && user.permissions && user.permissions.includes('view_advisors_report')"
              class="btn btn-danger btn-sm"
              target="_blank"
              :href="'/monthlyReport?month='+selectedMonth+'&report=advisor&timestamp='+ts">
              <b-icon icon="download"/> Advisor
            </a>
            <a v-if="user && user.permissions && user.permissions.includes('view_ofd_report')" class="btn btn-dark btn-sm"
              target="_blank"
              :href="'/monthlyReport?month='+selectedMonth+'&report=ofd&timestamp='+ts">
              <b-icon icon="download"/> OFD
            </a>
            <a class="btn btn-success btn-sm"
              target="_blank"
              :href="'/ProvinceReport?month='+selectedMonth+'&report=ofd&timestamp='+ts">
              <b-icon icon="download"/> Province Report
            </a>
            <a class="btn btn-success btn-sm ml-5"
              href="/logistic">
              <b-icon icon="truck"/> Requests
            </a>
            <a  class="btn btn-success btn-sm ml-5"
              @click="downloadSiteProfile()"
              target="_blank">
              <b-icon icon="file-earmark-spreadsheet"/> Download Site Profile
            </a>
            <!-- <router-link class="btn btn-secondary btn-sm"
              to="tab-dashboard">
              <b-icon icon="table"/> Dashboard
            </router-link> -->
            <!-- <a class="btn btn-warning btn-sm ml-5"
              target="_blank"
              :href="'/monthlyReport?month='+selectedMonth+'&report=flashreport&timestamp='+ts">
              <b-icon icon="lightning"/> Flash report
            </a> -->
            <b-button
            class="btn btn-warning btn-sm ml-5"
            variant="success"
            @click="flashReport()"
          >
          <b-icon icon="lightning"/> Flash report
          </b-button>
            <a v-if="user && user.permissions && user.permissions.includes('finance_check')"
              class="btn btn-danger btn-sm"
              target="_blank"
              :href="'http://176-dot-sims-2-140009.appspot.com/commcare/finance_report.php'">
              <b-icon v-if="sending === false" icon="camera"/> 
              <b-spinner v-else small variant="dark" label="Spinning"></b-spinner>
              Finance
            </a>
            <!-- sendFlashReport -->
            <!-- <a v-if="user && user.permissions && user.permissions.includes('send_flash_report')" -->
            <a
              class="btn btn-danger btn-sm"
              target="_blank"
              @click.prevent="modalSendEmailFlashReport">
              <b-icon v-if="sending === false" icon="wifi"/> 
              <b-spinner v-else small variant="dark" label="Spinning"></b-spinner>
              Send Flash Report
            </a>
          </span>        
          <div class="ml-auto">
            <b-input-group size="sm">
              <b-input-group-prepend is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input v-model="search" type="search" placeholder="Search site"></b-form-input>
            </b-input-group>
          </div>
        </div>
      </form>
      <table v-if="sites.length > 0" class="table">
        <thead class="cc-table-header">
          <tr style="line-height: 8px">
            <th scope="col"></th>
            <th v-for="platform in filteredPlatforms" class="text-center" scope="col" :key="'title'+platform">{{ platform }}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" class="row-title">Submitted</th>
            <th v-for="platform in filteredPlatforms" scope="col" :key="'sub'+platform">
              <SiteBall :class="{highlighted: site.site.includes(search) && search}" @siteSelected="showDetail(site)" :site="site" v-for="site in filteredSites(platform, 1)" :key="site.site"/>
            </th>
          </tr>
          <tr>
            <th scope="row" class="row-title">Not yet</th>
            <th v-for="platform in filteredPlatforms" scope="col" :key="'ns'+platform">
              <SiteBall :class="{highlighted: site.site.includes(search) && search, closed: site.closed || site.nop}" @siteSelected="showCloseModal(site)" :site="site" v-for="site in filteredSites(platform, 0)" :key="site.site"/>
            </th>
          </tr>
        </tbody>
      </table>
      <h3 v-if="sites.length === 0" class="pt-5">
        Please wait, we are loading your dashboard...<BR/>
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </h3>
      <b-modal ref="site-detail" id="site-detail" hide-footer size="xl">
        <template #modal-title>
          <!-- <a class="btn btn-secondary mr-1"
            download
            :href="'https://sims-2-140009.appspot.com/commcare/download-site-report-xlsx.php?site='+selectedSite.site+'&month='+monthToText(selectedMonth)+'&timestamp='+ts">
            <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
            Download excel report
          </a> -->
          <b-button v-if="selectedSite && selectedSite.isValidated === 0 && validating === false"
            @click="validateReport(selectedSite)"
            variant="outline-primary"
            class="mr-5">
            Validate report
          </b-button>
          <b-button v-else-if="validating === false" 
            @click="validateReport(selectedSite)"
            variant="success" class="mr-5">
            Report is valid
          </b-button>
          <b-spinner v-else variant="success" label="Spinning"></b-spinner>
          Site report for site <b>{{selectedSite.site}}</b>
        </template>
        <SiteDetail :site="selectedSite.site" :month="selectedMonth" :siteObject="selectedSite" @close="refreshAfterClosure()"/>
      </b-modal>
      <b-modal ref="site-closure" id="site-closure" hide-footer size="lg">
        <template #modal-title>
          Site closure <b>{{selectedSite.site}}</b>
        </template>
        <SiteClosureForm :selectedSite="selectedSite" @close="refreshAfterClosure()"/>
        <div v-if="selectedSite.closed === 1">
          ស្ថានីយ៏បិទទ្វារ / Site closed
          <BR/>Closure date : {{ selectedSite.closedReason.Closing_date | toDateString }}
          <BR/>មូលហេតុ / Reason : {{ selectedSite.closedReason.Closing_reason }}
          <BR/> {{ selectedSite.closedReason.Closing_reason_khmer }}
          <BR/> {{ selectedSite.closedReason.Closing_reason_english }}          
        </div>
        <!-- <div v-if="user && user.permissions && user.permissions.includes('close_site') && selectedSite.closed === 1" class="text-center mt-2">
          <h4>Do you want to report Site reopening ?</h4>
          <b-form-group label-cols="4" label-cols-lg="2" label="Reopening date" label-for="closure-date">
            <b-form-input id="closure-date" v-model="siteClosureDate" type="date"></b-form-input>
          </b-form-group>
          <b-button v-if="validating === false" variant="danger" @click="reopenSite(selectedSite)" class="mt-2 mb-4">Reopen site</b-button>
          <b-spinner v-else variant="success" label="Spinning"></b-spinner>
        </div> -->
      </b-modal>
      <!-- show school over budget-->
      <b-modal ref="sendEmailFlashReport" id="sendEmailFlashReport" hide-footer size="lg">
        <template #modal-title>
          <div>
          <p>   Please select which report you want to send email </p>
           </div>
          <div class="row">
            <div class="col-12">
              <b-form-select id="report_type" size="md" v-model="send_flash_report_type" :options="['Monthly_Flash_Report','Mid_Flash_Report']"></b-form-select>
            </div>
            <div class="col-12">
              <p></p>
            </div>
            <div class="col-12">
              <b-button variant="success" class="mr-4" @click="sendEmailFlashReport()">Download
            </b-button>
            <h3 v-if="downloadFlashReportSpinner" class="pt-5" align="center">
                  Please wait, downloading file<BR/>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            </h3>
            </div>

          </div>
        </template>
        <SiteClosureForm :selectedSite="selectedSite" @close="refreshAfterClosure()"/>
        <div v-if="selectedSite.closed === 1">
          ស្ថានីយ៏បិទទ្វារ / Site closed
          <BR/>Closure date : {{ selectedSite.closedReason.Closing_date | toDateString }}
          <BR/>មូលហេតុ / Reason : {{ selectedSite.closedReason.Closing_reason }}
          <BR/> {{ selectedSite.closedReason.Closing_reason_khmer }}
          <BR/> {{ selectedSite.closedReason.Closing_reason_english }}          
        </div>
        <div v-if="user && user.permissions && user.permissions.includes('close_site') && selectedSite.closed === 1" class="text-center mt-2">
          <h4>Please report site close in Commcare App</h4>
          <!-- <b-form-group label-cols="4" label-cols-lg="2" label="Reopening date" label-for="closure-date">
            <b-form-input id="closure-date" v-model="siteClosureDate" type="date"></b-form-input>
          </b-form-group>
          <b-button v-if="validating === false" variant="danger" @click="reopenSite(selectedSite)" class="mt-2 mb-4">Reopen site</b-button>
          <b-spinner v-else variant="success" label="Spinning"></b-spinner> -->
        </div>
      </b-modal>
      <b-modal ref="flashreport" id="flashreport" hide-footer size="lg">
        <template #modal-title>
          <div>
          <p>   Please select which report you want to download </p>
           </div>
          <div class="row">
            <div class="col-12">
              <b-form-select id="report_type" size="md" v-model="flash_report_type" :options="['Monthly_Flash_Report','Mid_Flash_Report']"></b-form-select>
            </div>
            <div class="col-12">
              <p></p>
            </div>
            <div class="col-12">
              <b-button variant="success" class="mr-4" @click="downloadFlashReport()">Download
            </b-button>
            <h3 v-if="downloadFlashReportSpinner" class="pt-5" align="center">
                  Please wait, downloading file<BR/>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            </h3>
            </div>

          </div>
        </template>
        <SiteClosureForm :selectedSite="selectedSite" @close="refreshAfterClosure()"/>
        <div v-if="selectedSite.closed === 1">
          ស្ថានីយ៏បិទទ្វារ / Site closed
          <BR/>Closure date : {{ selectedSite.closedReason.Closing_date | toDateString }}
          <BR/>មូលហេតុ / Reason : {{ selectedSite.closedReason.Closing_reason }}
          <BR/> {{ selectedSite.closedReason.Closing_reason_khmer }}
          <BR/> {{ selectedSite.closedReason.Closing_reason_english }}          
        </div>
        <div v-if="user && user.permissions && user.permissions.includes('close_site') && selectedSite.closed === 1" class="text-center mt-2">
          <h4>Please report site close in Commcare App</h4>
          <!-- <b-form-group label-cols="4" label-cols-lg="2" label="Reopening date" label-for="closure-date">
            <b-form-input id="closure-date" v-model="siteClosureDate" type="date"></b-form-input>
          </b-form-group>
          <b-button v-if="validating === false" variant="danger" @click="reopenSite(selectedSite)" class="mt-2 mb-4">Reopen site</b-button>
          <b-spinner v-else variant="success" label="Spinning"></b-spinner> -->
        </div>
      </b-modal>

    </div>
</template>

<script>
import SiteBall from './SiteBall'
import SiteDetail from './SiteDetail'
import SiteClosureForm from './SiteClosureForm'
export default {
   components: {
		SiteBall,
    SiteDetail,
    SiteClosureForm
  },
  name: "CommcareDashboard",
  data: function() { 
    return {
      search: "",
      downloadFlashReportSpinner:false,
      sites: [],
      ts: 0,
      selectedSite: {},
      selectedMonth: "",
      validating: false,
      siteClosureDate: "",
      sending: false,
      flash_report_type:'',
      send_flash_report_type:'',
      watchedForms : [
        "1. Register Sales",
        "2. Settle Invoices",
        "3. Register Expenses",
        "5. Production Report",
        "6. Site Checklist",
      ],
    }
  },
  watch: {
    selectedMonth(newVal) {
      this.reload(newVal)
    }
  },
  mounted() {
    let today = new Date();
    this.ts = Date.now()
    setInterval(() => {
      this.ts = Date.now()
    }, 5000);
    today.setDate(1)
    today.setMonth(today.getMonth() - 1)
    this.selectedMonth = today.toISOString().substring(0, 7)
  },
  methods: {
    flashReport() {
      this.$bvModal.show('flashreport')
    },
    modalSendEmailFlashReport() {
      this.$bvModal.show('sendEmailFlashReport')
    },
    reload(newVal) {
      this.sites = [];
      this.axios.get("/api/commcare?month="+this.monthToText(newVal)).then(response => {
        this.sites = response.data;
      });
    },
    downloadSiteProfile() {
      this.axios.get("https://sims3.teuksaat1001.com/api/sites/exportFromCloudSQL?ts="+(new Date()).toISOString(), {
        withCredentials: false,
        responseType: 'blob'
    }).then(response => {
      const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        }))
        const link = document.createElement('a');
        link.href = url;
        window.open(link,'_blank');
        link.setAttribute('download', "Sites.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    downloadFlashReport(){
      var period='';
      if(this.flash_report_type =='Monthly_Flash_Report')
          {
              period ='EndMonth';
          }
      else
          {
              period='MidMonth';
          }
      console.log("Period:"+period);
      this.downloadFlashReportSpinner=true;
      this.axios.get('/flashReport?month='+this.selectedMonth+'&period='+period+'&timestamp='+this.ts, {
        withCredentials: false,
        responseType: 'blob'
    }).then(response => {
      const url = URL.createObjectURL(new Blob([response.data], {
          type: 'application/vnd.ms-excel'
        }))
       // window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "Flash report"+"_"+this.selectedMonth+".xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.downloadFlashReportSpinner=false;
        this.$bvModal.hide('flashreport')
      });
    },
    sendEmailFlashReport(){
      var period='';
      if(this.send_flash_report_type =='Monthly_Flash_Report')
          {
            period ='EndMonth';
          }
      else
          {
            period='MidMonth';
          }
     // console.log("Period:"+period);
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure to send email of " + this.send_flash_report_type,
          {
            title: 'Confirmation',
            size: 'sm',
            okVariant: 'success',
            okTitle: 'YES',
            cancelTitle: 'NO',
          }
        )
        .then((confirmation) => {
          if (confirmation) {
           console.log("period =" + period)
             this.axios({
               method: "GET",
               url:
                 "/mailFlashReport?period="+period
             }).then(() => {
               this.$bvModal.msgBoxOk('The flash report has been sent !')
               this.sending = false
             });
          } else {
            this.sending = false
          }
        });
        
    },
    refreshAfterClosure() {
      this.$refs['site-closure'].hide()
      this.$refs['site-detail'].hide()
      this.reload(this.selectedMonth)
    },
    sendFlashReport(){
      this.sending = true;
      this.$bvModal
        .msgBoxConfirm(
          "This action will send an email to all configured staff. Do you really want to send the mail ?",
          {
            title: 'Confirmation',
            size: 'sm',
            okVariant: 'success',
            okTitle: 'YES',
            cancelTitle: 'NO',
          }
        )
        .then((confirmation) => {
          if (confirmation) {
            this.axios({
              method: "GET",
              url:
                "/mailFlashReport"
            }).then(() => {
              this.$bvModal.msgBoxOk('The flash report has been sent !')
              this.sending = false
            });
          } else {
            this.sending = false
          }
        });
    },
    reopenSite(site) {
      this.validating = true
      this.axios.post("/api/open", 
        {
          site: site.site,
          closureDate: this.siteClosureDate,
        }).then(() => {
          this.validating = false
          this.$refs['site-closure'].hide();
          this.reload(this.selectedMonth)
      });
    },
    validateReport(site) {
      this.validating = true
      this.axios.post("/api/validate", 
        {
          month: this.monthToText(this.selectedMonth),
          site: this.selectedSite.site
        }).then((response) => {
          this.validating = false
          site.isValidated = response.data
      });
    },
    showDetail(site) {
      this.selectedSite = site;
      this.$refs['site-detail'].show();
    },
    showCloseModal(site) {
      this.selectedSite = site;
      this.$refs['site-closure'].show();
    },
    monthToText(month) {
      const arr = month.split('-');
      return parseInt(arr[1])+ "-"+parseInt(arr[0]);
    },
    filteredSites: function(platform, submitted) {
      return this.sites.filter(site => {
        return (this.getNumberOfForms(site) > 0) == submitted && site.platform.toUpperCase() == platform;
      });
    },
    getNumberOfForms: function(site) {
      return this.watchedForms.filter(item => site.forms.indexOf(item) >= 0).length;
    }
  },
  computed: {
    platforms: function() {
      const platforms = [];
      this.sites.forEach( site => {
        if (platforms.indexOf(site.platform.toUpperCase()) === -1) {
          platforms.push(site.platform.toUpperCase());
        }
      });
      return platforms;
    },
    filteredPlatforms: function() {
      return this.platforms.filter(el => {
        return el != "STOP" && el != "PC";
      });
    },
    user() {
      return this.$store.state.user
    }
  },
  filters: {
    toDateString(val) {
      const d = new Date(Date.parse(val))
      return d.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>

<style>
.row-title {
  font-size: small;
  font-weight: normal;
  vertical-align: middle;
}
.action-bar > span > a {
  margin: 0px 5px 0px 5px;
}
.action-bar {
  width: 100%;
}
.app-title {
  font-size: 1.5rem;
  text-align: left;
}
.cc-table-header {
  background-color: #36a674;
  color: white;
}
.search-input {
  position: absolute;
  right: 2px;
}
@media (max-width: 1200px) {
  .search-input {
    position: relative;
  }
}
.highlighted > .site {
  box-shadow: 0 0 5px 3px red;
}
.closed > .site {
  background-color: #e9ecef;
  border-color: red;
}
</style>